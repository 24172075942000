body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  /* overflow-x: hidden; */
}
/* html {
  overflow-x: hidden;
} */
.mainlogo {
  width: 150px;
  height: 50px;
  background-size: cover;
}
#font-family-poppins {
  font-family: "Poppins";
  font-weight: 500;
}
.firstImageStyle {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}
@keyframes button-avl {
  from {
    opacity: 0.5;
    clip-path: inset(100% 100% 100% 100%);
  }
  to {
    opacity: 1;
    background-color: #048b57;
    clip-path: inset(0 0 0 0);
  }
}
input,
textarea::placeholder {
  font-size: 20px;
  font-family: "Poppins";
}
.animation-apply-btn {
  animation-name: button-avl;
  animation-duration: 1.5s;
}
@keyframes image-second {
  from {
    opacity: 0.8;
    scale: 0.8;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
.animation-apply-image-second {
  animation-name: image-second;
  animation-direction: linear;
  animation-timeline: view();
  animation-duration: 0.5s;
}
@keyframes microscope-image {
  from {
    opacity: 0.5;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.animation-micro-image {
  animation-name: microscope-image;
  animation-timeline: view();
}
.gradient-color {
  background-image: linear-gradient(to top, #048956, #ffffff);
}
.padding-20 {
  padding: 20px;
}
.width-70 {
  width: 70%;
  @media (max-width: 850px) {
    width: 100%;
  }
}
.font-fam {
  font-family: "Poppins";
}
.word-break {
  word-break: break-all;
}
.display-pro-flex {
  display: flex;
}
.text-size {
  font-size: 20px;
}
.opacity {
  opacity: 0.5;
}
.card:hover {
  transform: translateY(2px);
  cursor: pointer;
}
.width-30 {
  width: 30%;
}
.box-shadow {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
